import * as React from "react";
import * as ReactDOM from "react-dom";

const fontByHBI = 'texgyreadventorregular';

// the margins on the img element are to partially counteract the "get" and "by HB International" texts for balance
const CentaraByHBI: React.SFC<{}> =
    ({ }) => <div style={{ display: 'inline-block' }}>
        <img src="/images/Centara.svg" style={{ width: '400px', marginTop: '20px', marginRight: '60px' }} />
    </div>;

const CentaraBorder: React.SFC<{}> =
    ({ }) => <div className="centaraBorder" style={{ display: 'flex', justifyContent: 'center' }}><CentaraByHBI /></div>;


const LoginForm: React.SFC<{}> =
    ({ }) =>
        <div className="loginForm">
            <div className="input">
                <label>Username</label>
                <input />
            </div>
            <div className="input">
                <label>Password</label>
                <input type="Password" />
            </div>
            <button>Login</button>
        </div>;


const marketingText = [`HB International is a leading supplier of retail management solutions.
Centara is the flagship product, which has been 
sold and installed on over 50,000 POS worldwide.`,
    `HBI draws on over two decades of experience and active 
participation  developing standards for retail technologies.`];


type FileGroups = 'standard_libraries' | 'sql_server_32bit' | 'sql_server_64bit';
type Installer = {
    group: FileGroups;
    filename: string;
    version: string;
    name: string;
};

const serverPrerequisites: Prerequisite[] = [
    { folder: 'vc_redist', file: 'vc_redist.x86.exe', version: '2015', os32vs64specific: false, name: 'Visual C++ redistributable' },
    // .NET 4.62
];
const posPrerequisites: Prerequisite[] = [
    { folder: 'vc_redist', file: 'vc_redist.x86.exe', version: '2013', os32vs64specific: false, name: 'Visual C++ redistributable' },
    { folder: 'SqlLocalDB', file: 'SqlLocalDB.msi', version: '2014', os32vs64specific: true, name: 'SQL Server LocalDB' },
    { folder: 'MSOdbcSql', file: 'msodbcsql.msi', version: '13.1', os32vs64specific: true, name: 'MS ODBC' },
    { folder: 'SqlNCli', file: 'sqlncli.msi', version: '11', os32vs64specific: true, name: ' SQL Server Native Client' },
    // .NET 4.0
    // { target: 'pos', name: '/xxx">KBxxx for XP based PosReady</Link>
];
const optionalDownloads: Prerequisite[] = [
    { folder: 'SSMS', file: 'SSMS-Setup-ENU.exe', version: '2018', os32vs64specific: false, name: 'SQL Server Managment Studio' },
    { folder: 'SQLExpress', file: 'SQLEXPRWT_x86_ENU.exe.zip', version: '2008R2', os32vs64specific: false, name: 'SQL Express 2008 R2' },
    { folder: 'CCO', file: 'OPOS_CCOs_1.14.001.msi', version: '1.14', os32vs64specific: false, name: 'OPOS Common Control Objects' },
    { folder: 'Epson', file: 'Epson OPOS 2.8E R2.zip', version: '2.8E', os32vs64specific: false, name: 'Epson OPOS drivers' },

];

type Prerequisite = {
    name: string;
    version: string;
    os32vs64specific: boolean;
    folder: string;
    file: string;
};

type OS = 32 | 64 | 'both';
const osFolder = (os: OS) => os === 32 ? '32bit/' : os === 64 ? '64bit/' : '';
const prerequisitePath = (p: Prerequisite, os: OS) => `api/Download/DownloadFile/${encodeURIComponent(p.folder)}/${encodeURIComponent(p.version)}/${osFolder(os)}${encodeURIComponent(p.file)}`

const PrerequisitesList: React.SFC<{ elements: Prerequisite[] }> =
    ({ elements }) => <div className="downloadLinks">
        <ul>
            {elements.filter(p => !p.os32vs64specific).map(p => <li>
                <a href={prerequisitePath(p, 'both')}>{p.name} {p.version}</a>
            </li>)}
        </ul>

        {elements.some(p => p.os32vs64specific) ?
            <div className="bitnessSpecific">
                <div className="row">
                    <div className="column">
                        <h3>32bit Windows</h3>
                        <ul>
                            {elements.filter(p => p.os32vs64specific).map(p => <li>
                                <a href={prerequisitePath(p, 32)}>{p.name} {p.version}</a>
                            </li>)}
                        </ul>
                    </div>
                    <div className="column">
                        <h3>64bit Windows</h3>
                        <ul>
                            {elements.filter(p => p.os32vs64specific).map(p => <li>
                                <a href={prerequisitePath(p, 64)}>{p.name} {p.version}</a>
                            </li>)}
                        </ul>
                    </div>
                </div>
            </div>
            : null}
    </div>;

const FrontPage: React.SFC<{}> =
    ({ }) => <div className="frontPage">
        {/*<LoginForm />*/}
        <div className="content prerequisites">
            <div>
                <h2>Server prerequisites</h2>
                <PrerequisitesList elements={serverPrerequisites} />
            </div>
            <div>
                <h2>POS prerequisites</h2>
                <PrerequisitesList elements={posPrerequisites} />
            </div>
            <div>
                <h2>Optional downloads</h2>
                <PrerequisitesList elements={optionalDownloads} />
            </div>

        </div>
    </div>;


export const GetCentara: React.SFC<{}> =
    ({ }) =>
        <div className="getCentaraApp">
            <div style={{ alignSelf: 'stretch' }}>
                <CentaraBorder />
            </div>
            <FrontPage />
        </div>;