import * as React from 'react';
import { Component } from 'react';
import { GetCentara } from './components/GetCentara';

import './custom.css'

export default class App extends Component {
  static displayName = App.name;

  render () {
    return (<GetCentara/>);
  }
}
